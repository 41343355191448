:root {
    /* --transaction-row-height: minmax(30px, min(50px, max-content)); */
    --transaction-row-height: 28px;
}

.transaction-navigation-button {
    padding: 0px;
}

.transaction-navigation-button + span {
    margin-left: 0.2em;
}

span + .transaction-navigation-button {
    margin-left: 0.2em;
}

.trans-table-amount-title {
    text-align: left;
    color: var(--color-text-secondary);
    font-size: 13px;
    padding-right: 0.5em;
}

/* The info button. */
.transaction-line > button > .bi::before {
    vertical-align: unset;
}

.transactions {
    overflow-x: scroll;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.transactions div {
    display: contents;
}

.transactions div:nth-child(2n + 1) > * {
    background: none;
}
.transactions div:nth-child(2n) > * {
    background: var(--separating-line);
}

.transaction-table-header.transaction-line.transaction-line {
    position: sticky;
    background: none;
    color: var(--color-primary);
    width: 100%;
    padding-top: 0px;
    height: var(--transaction-row-height);
    font-size: 13px;
}

.transaction-header.transaction-header {
    color: var(--color-primary);
    font-size: 13px;
}

.transaction-table-header-wire {
    color: var(--color-primary);
    width: 100%;
    padding-top: 0px;
    height: var(--transaction-row-height);
    font-size: 13px;
    text-align: center;
    vertical-align: top;
    font-weight: 300;
}

@media (width > 700px) {
    .transactions,
    .transaction-table-header-wire,
    .transaction-table-header.transaction-line.transaction-line {
        min-width: 900px;
    }
}

.transaction-line {
    grid-auto-rows: auto;
    display: grid;
    margin: 0px;
    padding: 0px;
    font-size: 12px;
    border: none;
    color: var(--color-text-main);
    align-items: center;
}

.transaction-line select,
.transaction-line input,
.transaction-line span {
    width: 100%;
    height: 100%;
    padding: 5px 2.5px;
    font-size: 12px;
    color: var(--color-text-main);
    text-align: left;
    line-height: 1rem;
    align-items: center;
    vertical-align: middle;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.transaction-line button {
    vertical-align: top;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 100%;
    border-radius: 0px;
}

.transaction-line span[style*="amount"] {
    display: none;
}

.transaction-line:not(.transaction-table-header) *[style*="description"] {
    font-size: 11px;
    text-overflow: ellipsis;
    width: unset;
}

.transaction-line:not(.transaction-table-header) *[style*="paymentMethod"],
.transaction-line:not(.transaction-table-header) *[style*="bill"],
.transaction-line:not(.transaction-table-header) *[style*="check"],
.transaction-line:not(.transaction-table-header) *[style*="deposit"] {
    font-size: 11px;
}

.transaction-line:not(.transaction-table-header) *[style*="bill"],
.transaction-line:not(.transaction-table-header) *[style*="check"],
.transaction-line:not(.transaction-table-header) *[style*="deposit"],
.transaction-line:not(.transaction-table-header) *[style*="debit"],
.transaction-line:not(.transaction-table-header) *[style*="credit"] {
    text-align: right;
}

.alignright {
    text-align: right;
}

.alignright-small {
    text-align: right;
    font-size: 11px;
}

.align-left {
    text-align: left;
}

.account-balances {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    width: max-content;
}

.account-balances > *:nth-child(odd) {
    margin-right: 0.25em;
}

.account-balances > *:nth-child(even) {
    margin-right: 2em;
}

/* Bottom bar: edit, navigation, export, and new transaction buttons */
.trans-table-bottom-bar {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, auto) 1fr repeat(2, 30px);
    grid-template-rows: 30px;
    gap: 5px;
    margin-top: 10px;
}

.trans-table-bottom-bar > button,
.trans-table-bottom-bar > div > button {
    width: 100%;
    height: 100%;
}

.transaction-navigation-button {
    grid-area: 1 / 6 / 1 / 6;
}

/* The second navigation button */
.transaction-navigation-button + .transaction-navigation-button {
    grid-area: 1 / 7 / 1 / 7;
}

.transaction-navigation-button:hover {
    color: var(--color-border);
}

.transaction-line.transaction-table-header span[style*="amount"] {
    display: none;
}

.transaction-table-header span {
    color: var(--color-primary);
}

@media (width < 700px) {
    .transactions,
    .transaction-table-header.transaction-line.transaction-line {
        min-width: 100%;
    }
    .transaction-line span {
        display: none;
    }

    .transaction-line span[style*="date"],
    .transaction-line span[style*="counterpart"],
    .transaction-line span[style*="reconciled"] {
        display: -webkit-box;
    }

    .transaction-line {
        grid-template-columns: [edit] 20px [date] 70px [counterpart] 1fr [amount] 65px [reconciled] 20px !important;
    }

    .transaction-line span[style*="credit"],
    .transaction-line span[style*="debit"] {
        display: none;
    }

    .transaction-line span[style*="amount"] {
        display: -webkit-box;
        grid-column: amount !important;
    }

    .trans-table-bottom-bar {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
    }

    .transaction-navigation-button {
        grid-area: 3 / 1 / 3 / 1;
    }
    .transaction-navigation-button + .transaction-navigation-button {
        grid-area: 3 / 2 / 3 / 2;
    }

    .date-range {
        display: flex;
        flex-direction: row;
        gap: 0.5em;
        width: 100%;
    }

    .account-balances {
        display: grid !important;
        grid-template-columns: 50% 50%;
        width: 100%;
    }
}
