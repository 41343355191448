table > tbody > tr > td:first-child {
    padding-right: 5px;
    padding-top: 2px;
}

.unit-alert-no-owner {
    width: fit-content;
    max-width: 800px;
    margin: 0px;
    font-size: 1rem;
}
