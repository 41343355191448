@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

/* form .modal-footer {
    padding: 10px 0px 0px 0px;
} */

/* .modal-footer > button[class*="btn-outline-"] {
    margin-right: 0.5em;
}

.modal-footer > button[class*="btn-outline-"] + button[class*="btn-outline-"] {
    margin-right: 0px;
} */

/* .dropdown {
    max-width: 300px;
} */
