.posts-list {
    display: flex;
    flex-direction: column;
    margin: 1em 0px;
    max-width: 900px;
}

.posts > .trans-table-bottom-bar {
    max-width: 900px;
    margin-top: 20px;
}

button.pagination-button {
    min-width: 30px;
}

button.pagination-button > i {
    width: 100%;
    padding: 0px;
}

.template-grid {
    display: grid;
    grid-template-columns: 300px 400px auto;
    grid-template-rows: auto;
    row-gap: 10px;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 50px;
}

.template-grid-divider {
    grid-column: 1/4;
    height: 2px;
    margin-top: 0px;
}

@media (width < 700px) {
    .template-grid-divider {
        grid-column: 1/3;
    }
    .template-grid {
        grid-template-columns: auto auto;
    }
}
