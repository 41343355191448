@keyframes comeInFromTop {
    0% {
        top: -50px;
    }
    100% {
        top: 230px;
    }
}

@keyframes comeInFromTopToTop {
    0% {
        top: -50px;
    }
    100% {
        top: 0px;
    }
}
