.report-grid {
    float: left;
    display: grid;
    grid-template-columns: 30px 300px 94px;
    grid-auto-rows: 50px;
    align-items: center;
    padding: 10px;
}

.report-grid > div > button {
    margin-right: 0px;
}

.report-title {
    font-size: 15px;
}

@media (width < 700px) {
    .report-grid {
        width: 100%;
        grid-template-columns: 30px 1fr 94px;
        grid-auto-rows: 50px;
    }
}
