.dashboard h2 {
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 5px;
    font-weight: 500;
}

.accounts-summary details div {
    display: grid;
    grid-template-columns: 1fr 105px;
    gap: 5px;
}

.dashboard .accounts-summary details span:first-child {
    text-align: start;
}

.dashboard .accounts-summary details span:last-child {
    text-align: end;
    word-wrap: break;
    overflow: hide;
}

.dashboard-button-container {
    width: 110px;
    height: 90px;
    background-color: transparent;
    border-radius: 10px;
    border-style: solid;
    border-width: 1.5px;
    border-color: var(--color-primary);
    color: var(--color-primary);
    margin-left: 5px;
}

.dashboard-cash-flow > div {
    overflow: scroll;
    transition: 0.5s ease;
    border-radius: 10px;
    border-style: solid;
    border-width: 1.5px;
    text-align: center;
    min-width: 200px;
    padding: 10px;
    background-color: var(--mantine-color-gray-1);
}

.dashboard-cash-flow {
    padding: 0px;
    margin-top: 0px;
}

.dashboard-chart-title {
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 15px;
    font-weight: 500;
    padding: 10px;
}

.dashboard-chart-title {
    margin-top: 5px;
    margin-bottom: 2px;
    color: #0064b5;
}

.dashboard-cash-flow {
    width: 800px;
    height: 445px;
    background-color: var(--color-bg-main);
    border-color: #0064b5;
    border-width: 1px;
    border-style: solid;
}

.dashboard .dashboard-alerts {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    gap: 20px;
    color: var(--color-text-secondary);
}

.dashboard-alerts > .alert > .h4 {
    font-size: 20px;
    margin-bottom: 5px;
}

.dashboard-alerts > .alert > p {
    color: var(--color-text-secondary);
    font-size: 14px;
}

@media (width < 700px) {
    .dashboard .building-summary {
        display: grid;
        grid-template-columns: auto auto;
        margin-left: 5px;
    }

    .dashboard-chart-grid {
        display: flex;
        grid-row-start: 4;
        grid-row-end: 5;
        grid-column-start: 1;
        grid-column-end: 3;
    }
    .vertical-divider {
        display: none;
    }
    .dashboard-cash-flow {
        width: 100%;
        height: unset;
    }

    .dashboard-button-container {
        width: calc((100% / 3) - 5px);
        margin-bottom: 5px;
    }

    .dashboard .accounts-summary {
        grid-template-columns: 1fr;
        margin-left: 5px;
        margin-right: 5px;
    }
}
