@layer components {
    h1 > .root,
    h2 > .root,
    h3 > .root,
    h4 > .root,
    h5 > .root,
    h6 > .root {
        margin-inline-start: 0.25rem;
        vertical-align: middle;
    }
}
