:root {
    --navbar-width: 60px;
    /* --main-list-width: 250px; */
    /* --main-list-internal-width: calc(var(--main-list-width) - 1px); */
    --main-list-secondary-font-size: 0.8rem;
    --main-width-mobile: calc(100% - var(--navbar-width));
}

.secondary-navbar-container.secondary-navbar-container {
    padding: 0px;
    padding-top: 3px;
    z-index: 1;
    overflow-y: scroll;
    background-color: var(--mantine-color-body);
    --tab-border-color: var(--color-primary);
    --tab-border-width: 2px;
}

.budget-buttons {
    display: flex;
    align-items: flex-end;
    column-gap: 5px;
}

.main-view {
    padding: 1rem;
    padding-top: 0rem;
}

@media (width > 700px) {
    .list-only-element {
        font-size: 14px !important;
    }
}

@media (width < 700px) {
    :root {
        --main-list-width: 100%;
        --main-list-secondary-font-size: 17px;
    }

    .navbar-container.collapsed ~ .main-view,
    .navbar-container.collapsed ~ .main-list ~ .main-view {
        padding: 0px;
    }

    /* When the main list is not in focus, hide it. */
    .main-list:has(~ .main-view.focused) {
        display: none;
    }

    /* When the main view is not in focus, hide it. */
    .main-list ~ .main-view.main-view:not(.focused) {
        display: none;
    }

    /* .main-list {
        border-right-width: 0px;
    } */

    /* .main-list .list-only-element,
    .main-list .list-first-element {
        padding-top: 7px;
        padding-bottom: 7px;
    } */
    /* .list-second-element {
        padding-top: 4px;
    } */
}
