.wire-transfer-grid {
    display: grid;
    padding-left: 40px;
    width: 90%;
    grid-template-columns: 130px repeat(2, auto);
    font-size: 14px;
    line-height: 25px;
    align-items: center;
}

.wire-transfer-grid > h4 {
    padding: 0px;
    margin: 0px;
    font-size: 13px;
}

.wire-transfer-grid-divider {
    grid-column: 1/4;
    padding: 0px;
    margin: 5px 0px;
    align-items: center;
}
