.list {
    @media (max-width: 700px) {
        flex-wrap: nowrap;
        overflow-y: scroll;
        width: fit-content;
    }
}

.tab {
    font-size: 1rem;
    &[data-active] {
        background-color: var(--mantine-color-primary-light);
        & .tabLabel {
            color: var(--color-primary);
            font-weight: 500;
        }
        & .tabSection {
            color: var(--color-primary);
        }
    }
}

.tabLabel {
    font-weight: 300;
}

button[data-active] > .itemLabel {
    color: var(--color-primary);
}
