@layer components {
    .root {
        max-width: 950px;
        margin-bottom: 5px;
    }

    .title {
        font-weight: 400;
        font-size: 14px;
    }

    .icon {
        font-weight: 500;
    }

    .message,
    .message > p {
        font-weight: 300;
        color: var(--details-font);
    }
}
