@layer reset {
    /* Reset all margins and paddings for all elements to 0 */
    * {
        padding: 0px;
        margin: 0px;
    }

    body {
        margin: 0;
        font-family:
            "Open Sans",
            -apple-system,
            BlinkMacSystemFont,
            "Segoe UI",
            "Roboto",
            "Oxygen",
            "Ubuntu",
            "Cantarell",
            "Fira Sans",
            "Droid Sans",
            "Helvetica Neue",
            sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        line-height: 100%;
    }

    h1,
    h2,
    h3,
    h4,
    /* h5, */
    /* h6, */
    p {
        font-weight: 300;
    }

    h1,
    h2,
    h3,
    h4 {
        color: var(--color-text-main);
    }

    h1 {
        font-size: 26px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-weight: 500;
        line-height: 35px;
    }

    h2 {
        font-size: 20px;
        margin-top: 50px;
        margin-bottom: 15px;
        font-weight: 500;
    }

    h3 {
        margin-bottom: 10px;
        font-size: 15px;
        font-weight: 500;
    }

    h4 {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
    }

    a {
        color: var(--color-primary);
        text-decoration: unset;
    }

    a:hover {
        color: unset;
        text-decoration: unset;
    }

    p {
        color: var(--color-text-main);
        line-height: var(--min-line-height);
        font-weight: 300;
    }

    select,
    input {
        border-radius: 6px;
        padding: 11px;
        padding-left: 8px;
        border-width: 1px;
        border-color: var(--color-border);
        border-style: solid;
        color: var(--color-text-main);
        background-color: var(--navbar-bg);
        text-align: start;
        margin: 0px;
        justify-content: space-between;
    }

    input,
    select {
        height: 22px;
    }

    form select,
    form input {
        height: unset;
        max-width: unset;
    }

    input[type="checkbox"] {
        padding: unset;
        max-width: 20px;
    }
    input[type="date"] {
        width: unset;
    }

    input[type="date"]:not([class^="form-"]) {
        border-width: 1px;
        border-color: var(--color-border);
        border-style: solid;
        text-align: left;
        color: var(--color-primary-dark);
    }

    input[type="date"][disabled]:not([class^="form-"]) {
        background: none;
        border-style: none;
        width: 80%;
    }

    /* Remove the calendar picking icon when the input is*/
    input[type="date"][disabled]::-webkit-inner-spin-button,
    input[type="date"][disabled]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
        appearance: none;
    }

    /* Hide increment arrows in input type=number */
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
        appearance: textfield;
    }

    table {
        table-layout: fixed;
    }

    hr.menu {
        border: none;
        padding: 0px;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    summary i {
        font-size: 14px;
        font-weight: 300;
    }
}
