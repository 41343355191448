@layer components {
    .root {
        margin-top: 50px;
        margin-bottom: 20px;
    }

    .label {
        font-size: 18px;
        font-weight: 200;
        color: var(--color-text-secondary);
        text-transform: uppercase;
    }
}
