.appnflat-blue {
    color: #4dabf7;
}

.signin > form {
    max-width: 400px;
    margin-top: max(calc((100vh / 2) - 300px), 40px);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.signin > form > div {
    text-align: left;
}
