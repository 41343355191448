@layer components {
    .root {
        font-size: 1rem;
        &[data-variant="long"],
        &[data-variant="table"],
        &[data-variant="table-first-row"],
        &[data-variant="data"],
        &[data-variant="popup"] {
            --input-height: var(--mantine-line-height);
            & .label {
                color: var(--color-text-secondary);
                font-weight: 300;
                font-size: 14px;
            }
        }

        &[data-variant="data"] {
            width: 360px;
            & .body {
                grid-template-columns: 180px 30px;
            }
        }

        &[data-variant="long"] {
            width: 570px;
            & .body {
                grid-template-columns: 400px 30px;
            }
        }

        &[data-variant="default"] {
            & .body {
                gap: 0px 0.5rem;
            }
        }

        &[data-variant="popup"] {
            margin-top: 0.5rem;
            & .body {
                grid-template-columns: 150px 30px;
            }
        }

        &[data-variant="table"] {
            margin-top: 0.25rem;
            & .label {
                display: none;
            }
        }

        &[data-variant="table"],
        &[data-variant="table-first-row"] {
            & .description,
            & .error {
                display: none;
            }
        }

        &[data-variant="table-first-row"] {
            margin-top: 0.5rem;
        }
    }

    @media (width < 700px) {
        .root {
            &[data-variant="long"] {
                grid-template-columns: 1fr 30px;
            }

            &[data-variant="popup"] {
                width: unset;
                & .body {
                    width: 100%;
                }
            }
        }
    }

    .labelWrapper {
        grid-area: label;
    }

    .error {
        grid-area: error;
    }

    .description {
        grid-area: description;
    }

    .inner {
        grid-area: checkbox;
    }

    .body {
        display: grid;
        grid-template:
            "label checkbox" auto
            "description description" auto
            "error error" auto / 1fr 25px;
    }
}
