@layer components {
    .wrapper:not([data-multiline], [data-variant="default"]) {
        height: 1.75rem;
    }

    .wrapper:not([data-multiline], [data-variant="default"]) .input {
        --input-size: 1.75rem;
        --input-height: 1.5rem;
        --input-line-height: 1.3rem;
    }

    .input {
        grid-area: input;
        font-size: 14px;

        &[data-variant="long"],
        &[data-variant="table-first-row"],
        &[data-variant="table"],
        &[data-variant="data"],
        &[data-variant="popup"] {
            border: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-5);

            &[data-disabled] {
                border: none;
                color: var(--mantine-color-text);
                background-color: unset;
                cursor: unset;
                opacity: 1;
                padding-right: 0px;
                padding-left: 0px;
            }
        }

        &[data-error] {
            border-color: var(--color-danger);
        }
    }

    .section {
        background-color: var(--color-bg-main);
    }
}
