.root {
    padding-top: rem(80px);
    padding-bottom: rem(80px);
}

.title {
    font-family:
        Greycliff CF,
        var(--mantine-font-family);
    text-align: center;
    font-weight: bold;
    font-size: rem(42px);
}

.description {
    font-family:
        Greycliff CF,
        var(--mantine-font-family);
    text-align: center;
    font-weight: bold;
    font-size: rem(32px);
}
