@layer components {
    .label {
        grid-area: label;
        font-size: 14px;

        &[data-variant="long"],
        &[data-variant="table-first-row"],
        &[data-variant="data"],
        &[data-variant="popup"] {
            color: var(--color-text-secondary);
            font-weight: 300;
        }

        &[data-variant="table"] {
            display: none;
        }
    }

    .root {
        font-size: 1rem;

        &[data-variant="popup"] {
            margin-top: 0.5rem;
        }

        &[data-variant="table-first-row"] {
            margin-top: 0.5rem;
        }

        &[data-variant="table"] {
            margin-top: 0.25rem;
        }
    }

    .error {
        &[data-variant="table"],
        &[data-variant="table-first-row"] {
            display: none;
        }
    }

    .description {
        &[data-variant="table"],
        &[data-variant="table-first-row"] {
            display: none;
        }
    }
}
