.pulse {
    display: block;
    transition: all 0.15s ease 0s;
    animation: pulse 3s ease infinite;
    animation-fill-mode: both;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
