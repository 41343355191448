@layer reset, mantine, components;
@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,300&family=Work+Sans&display=swap");

:root {
    /** COLORS */
    --color-primary: var(--mantine-color-primary-4);
    --color-danger: var(--mantine-color-error);
    --color-success: var(--mantine-color-green-6);
    --color-warning: var(--mantine-color-orange-6);
    --mantine-color-primary-filled: var(--color-primary) !important;
    --mantine-color-primary-filled-hover: var(--mantine-color-primary-2) !important;

    /** Text colors. */
    --color-text-main: var(--mantine-color-text);
    --color-text-secondary: var(--mantine-color-gray-6);

    /** Background colors. */
    --color-bg-main: #fff;
    --color-bg-secondary: var(--mantine-color-gray-1);

    --color-border: var(--mantine-color-gray-3);
    --color-help-border: var(--mantine-color-orange-3);
    --details: var(--mantine-color-gray-0);
    --details-font: var(--mantine-color-dark-3);
    --separating-line: var(--mantine-color-gray-1);
    --navbar-bg: var(--mantine-color-gray-0);
    --input-height: 22px;
    --min-line-height: 28px;

    @mixin dark-root {
        --color-bg-main: #000;
        /* --separating-line: var(--mantine-color-gray-1); */
        --navbar-bg: var(--mantine-color-gray-9);
        --color-bg-main: var(--mantine-color-dark-7);
        --color-bg-secondary: var(--mantine-color-gray-8);
    }

    --main-list-bg: var(--color-bg-secondary);
}

html {
    font-size: 14px;
    font-weight: 300;
}

details summary::-webkit-details-marker {
    display: none;
}

.tabler-icon {
    font-size: 1em;
    width: 1.25em;
    height: 1.25em;
    stroke-width: 1.25;
}

.center {
    display: flex;
    justify-content: center;
}

.static-main-view {
    top: 50px;
}

.signin {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.signin > form {
    display: grid;
    flex-direction: column;
}

.signin > form > input {
    margin-bottom: 5px;
    max-width: 400px;
}

.transaction-line > .delete {
    height: 25px;
}

/* .rotate-infinite {
    animation: rotate 1s linear infinite;
    width: 50px;
    height: 50px;
} */

.mobile-only {
    display: none;
}

.web-only {
    display: block;
}

/** A set of fields. */
.fields {
    display: flex;
    flex-flow: row wrap;
    gap: 0.3rem 3rem;
    max-width: 860px;
    margin-bottom: 0.3rem;
    justify-content: start;
    align-content: baseline;
}

.fields + .fields {
    margin-top: unset;
}

/** A group of a set of fields (contains multiple `.fields`) */
.fields-tag {
    display: grid;
    grid-template-columns: 27rem 27rem 27rem;
    gap: 1rem 1rem;
    min-height: var(--min-line-height);
}
.fields-group {
    /* display: flex; */
    /* flex-flow: row wrap; */
    display: grid;
    grid-template-columns: 30rem 30rem;
    gap: 2rem 3rem;
    /* max-width: 60rem; */
    min-height: var(--min-line-height);
}

.fields-group > * {
    flex-basis: 370px;
}

.button-group {
    display: flex;
    align-items: flex-end;
    flex-flow: row wrap;
    gap: 0rem 1rem;
}

.fields-1column {
    display: flex;
    flex-flow: row wrap;
    gap: 0.3rem;
    margin-bottom: 0.3rem;
    max-width: 30rem;
}

.label-and-value {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    min-height: var(--min-line-height);
    margin: 0px;
}

.label-and-value > *:first-child {
    width: 175px;
    line-height: min(var(--min-line-height));
}

.label-and-value > *:last-child {
    width: 200px;
}

@media (width < 700px) {
    .mobile-only {
        display: block;
    }

    .web-only {
        display: none;
    }

    input {
        font-size: 14px !important;
    }
}

/** Spacing for notes that appear in popups below a field. */
.row:has(+ .row > .form-text) {
    margin-bottom: 0px !important;
}

.md-text a {
    text-decoration: underline;
    color: var(--primary);
}

.md-text ol:last-child {
    margin-bottom: 0px;
}

@media (width < 700px) {
    :root {
        font-size: 17px;
        --input-height: 25px;
    }
    html {
        font-size: 17px;
    }
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

*[data-link] {
    cursor: pointer;
    color: var(--color-primary);
}

.safe-area-padding {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
        env(safe-area-inset-left);
}

.form-check > input[type="checkbox"] {
    margin-top: 0px;
}

.file-input {
    margin-top: 6px;
    vertical-align: baseline;
}

.file-input > label.custom-file-input > input[type="file"] {
    display: none;
    margin-top: 0px;
}
.file-input.file-input > * {
    height: var(--input-height);
    margin-top: 0px;
}

.file-input.file-input > span {
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
}

.export-button {
    display: flex;
}

.resize-handle {
    width: 10px;
    height: 20px;
    background-color: var(--mantine-color-gray-3);
}