@layer components {
    .download-button {
        grid-area: upload;
        display: flex;
        gap: 0.5rem;
        border: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-text);
        border-radius: 0.25rem;
        align-items: center;
        justify-content: center;

        &[data-full-width] {
            grid-area: download;
            margin-top: 0.5rem;
        }
    }

    .delete-button {
        color: red;
        grid-area: delete;
        border: none;
        --ai-size: 20px;
    }

    .clear-button {
        color: gray;
        grid-area: delete;
    }

    .label {
        grid-area: label;
        font-size: 14px;

        &[data-variant="data"],
        &[data-variant="popup"] {
            color: var(--color-text-secondary);
            font-weight: 300;
        }
    }

    .root {
        font-size: 1rem;
        &[data-variant="data"] {
            display: grid;
            grid-template:
                "label upload delete" auto
                ". download ." auto
                "description description description" auto
                "error error error" auto / 180px 150px 30px;
        }

        &[data-variant="popup"] {
            display: grid;
            grid-template:
                "label upload delete" auto
                ". download ." auto
                "description description description" auto
                "error error error" auto / 150px 1fr 30px;
            margin-top: 0.5rem;
        }
    }

    @media (width < 700px) {
        .root {
            &[data-variant="popup"] {
                grid-template:
                    "label label" auto
                    "upload delete" auto
                    "download ." auto
                    "description description" auto
                    "error error" auto / 1fr 30px;
            }
        }
    }

    .error {
        grid-area: error;
        color: var(--color-danger);
        font-size: var(
            --input-error-size,
            calc(var(--mantine-font-size-sm) - calc(0.125rem * var(--mantine-scale)))
        );
    }

    .description {
        grid-area: description;
    }

    .upload-button {
        grid-area: upload;
        font-size: 14px;
        --button-height: 20px !important;

        &[data-variant="data"],
        &[data-variant="popup"] {
            border: calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-gray-5);

            &[data-disabled] {
                border: none;
                color: var(--mantine-color-text);
                background-color: unset;
                cursor: unset;
                opacity: 1;

                &:only-child {
                    padding-right: 0px;
                    padding-left: 0px;
                }
            }
        }

        &[data-error] {
            border-color: var(--color-danger);
        }
    }

    .section {
        height: var(--input-height);
    }
}
