@layer components {
    /**
     * Button custom variants: "icon"
     */

    .inner {
        font-weight: 400;
    }

    .root {
        /* --button-color: var(--color-primary); */

        &[hidden] {
            display: none;
        }

        &[data-variant="icon"] {
            border: none;
            background: none;
            color: var(--color-text-main);
        }

        &[data-variant="icon"][data-disabled] {
            cursor: not-allowed;
            color: var(--color-text-disabled);
        }

        & svg {
            font-size: 1em;
            width: 1.25em;
            height: 1.25em;
            stroke-width: 1.25;
            /* stroke: var(--button-color); */
        }

        &[data-variant="dashboard-shortcut"] {
            color: var(--color-text-main);
            border: 1px solid var(--color-border);
            border-radius: var(--mantine-radius-lg);
            height: rem(130);
            width: rem(150);
            padding-left: 10px;
            padding-right: 10px;
            background-color: var(--color-bg-secondary);
            flex-basis: 100%;

            & svg {
                font-size: 1.5rem;
                width: 3rem;
                height: 3rem;
                stroke: var(--color-primary);
                margin-bottom: 10px;
            }

            & .label {
                text-wrap: wrap;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                overflow-wrap: normal;
                font-size: 1.1rem;
                font-weight: 200;
            }
        }
    }

    .root[data-variant="icon"] .section {
        margin: 0px;
    }
}
