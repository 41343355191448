.link {
    width: 50px;
    height: 50px;
    border-radius: var(--mantine-radius-md);
    display: flex;
    align-items: center;
    justify-content: center;
    color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));

    &[data-is-logo] img {
        border-radius: var(--mantine-radius-md);
        @media (max-width: 700px) {
            width: 35px;
            height: 35px;
        }
    }

    &:hover:not([data-is-logo]) {
        background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-5));
    }

    &[data-active]:not([data-is-logo]) {
        &,
        &:hover {
            background-color: var(--mantine-color-primary-light);
            color: var(--mantine-color-primary-light-color);
        }
    }

    &[data-no-onclick] {
        cursor: help;
    }

    @media (max-width: 700px) {
        width: calc(100vw - 2rem);
        margin: 0px 1rem;
        text-align: left;
        padding-top: 14px;
        padding-bottom: 14px;
    }
}

.label {
    display: none;

    @media (max-width: 700px) {
        display: block;
    }
}

.burger {
    display: none;

    @media (max-width: 700px) {
        display: block;
        padding: 0px;
        grid-area: 1 / 3 / 1 / 3;

        & > i {
            font-size: 25px;
            font-weight: 500;
            color: white;
        }
    }
}

.navbar {
    border: none;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 700px) {
        &:not([data-expanded]) > * {
            display: none;
        }
    }
}

.title-bar {
    padding-top: 1rem;

    @media (max-width: 700px) {
        display: grid;
        grid-template-columns: 40px 1fr 40px;
        grid-template-rows: 50px;
        width: 100%;
        align-items: center;
    }
}
